import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams,} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {Observable} from "rxjs/internal/Observable";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
    //////console.log('esta es la ip: ',this.configService);
  }



  getLogin():Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(this.configService.serverIp.concat('/getLogin'), {headers});
  }

  getSocialMedia(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(this.configService.serverIp.concat('/getSocialMedia'), {headers});
  }


}
