import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { MultilingualHeaderImageService } from 'src/app/services/multilingual-header-image.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GuideStatusService } from 'src/app/services/guide-status.service';
import {AlertMessageService} from '../../services/alert-message.service';
import { ToastrService } from 'ngx-toastr';
import { GeneratePQRService} from 'src/app/services/generate-PQR.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-generate-PQR',
  templateUrl: './generate-PQR.component.html',
  styleUrls: ['./generate-PQR.component.scss']
})
export class GeneratePQRComponent implements OnInit {

  form:FormGroup;
  maxlengthSubject=100;
  maxlengthNotes=500;
  constructor(private multilingualHeadersService: MultilingualHeaderImageService,
    private toastr: ToastrService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private alertMessageService: AlertMessageService,
    private generatePQRService:GeneratePQRService) {
      this.buildForm();
      this.languageService.initLanguageConfig();
      this.translate.use(this.languageService.getCurrentLanguage())
    }

  ngOnInit() {
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      subject: ['',[Validators.required,Validators.maxLength(100)]],
      notes: ['',[Validators.required,Validators.maxLength(500)]],
      name_sender: ['',[Validators.required]],
      phone_sender: ['',[Validators.required]],
      email_sender: ['',[Validators.required,Validators.email]],
    });
    this.form.valueChanges
  }
  getFlagURL(): string {
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

    // Método para cambiar el lenguaje de la aplicación
    ChangeLanguage(language: string) {
      this.translate.use(language);
      localStorage.setItem('language', language);
    }

    /**
     *
     * @param event
     */
    generatePQR(event:Event){
      let alertTextPQR;
      let textCreatedPqr;
      this.languageService.getTranslateOf("PQR.alertPqrCreated").subscribe((response) =>{
        alertTextPQR=response;
      } );
      this.languageService.getTranslateOf("PQR.messageGeneratePqr").subscribe((response) =>{
        textCreatedPqr=response;
      } );
      event.preventDefault();
      if(this.form.valid){
        const value=this.form.value;
        this.generatePQRService.genereateTicketPQR(value).subscribe(result => {
          if(result['status']){
            this.clear();
            Swal.fire(
              alertTextPQR+result['ticket'].code,
              textCreatedPqr,
              'success'
            )
          }else{
            this.alertMessageService.showInfoMessage(this.languageService.getCurrentLanguage(), result['code']);
          }
        });
      }
    }


  /**
   * Metodo auxiliar para limpieza de datos
   */
  clear() {
    let data=this.form.value;
    for (let i in data) {
        this.form.get(i).setValue('');
    }
    this.buildForm();
  }
}
