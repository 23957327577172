import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import { map } from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorToken implements HttpInterceptor {
  constructor(
    private router: Router
  ) {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
    
    return next.handle(req).pipe(
      // map(event => {
      // if (event instanceof HttpResponse) {
      //    console.log(event.body)
      //    //message: "auth.policy.invalidToken"
      //    //status: false
      // }         
      //   return event;
      // }),
      catchError((error: HttpErrorResponse) => {
          let data = {};
          data = {
              reason: error && error.error && error.error.reason ? error.error.reason : '',
              status: error.status,
              message: error.error.message
          };
          if(data['message']=='auth.policy.invalidToken'){
            this.router.navigate(['/login']);
          }
          return throwError(error);
      }));
}
}
