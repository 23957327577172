import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GeneratePQRService {

  configService;
  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService();
  }

  /**
   *
   *
   * @param value
   */
  genereateTicketPQR(value) {
    //const params = new HttpParams().set(value);
    return this.httpClient.post(this.configService.serverIp.concat('/registerTicket'), value);
  }
}
