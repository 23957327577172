import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchHeightModule } from '../../shared/directives/match-height.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadPicturesComponent } from '../upload-pictures/upload-pictures.component';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from '../shared.module';


@NgModule({
  imports: [
    SharedModule,
    MatchHeightModule,
    NgbModule.forRoot()
  ],
  exports: [
    UploadPicturesComponent
  ],
  declarations: [
    UploadPicturesComponent
  ],
  providers: []
})

export class UploadPicturesModule { }

