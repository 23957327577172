import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  configService;
  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService()
  }

  getGuide(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearch'), value
      , { headers });
  }

  getGuideGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchGuide'), value
      , { headers });
  }

  /**
   * Servicio de peticion busqueda por nombre
   * @param value
   */
  getUserNameGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchName'), value
      , { headers });
  }

  /**
   * Servicio de peticion busqueda por telefono
   * @param value
   * @returns
   */
  getUserPhoneGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchPhone'), value
      , { headers });
  }

  /**
   * Servicio de peticion busqueda por anotaciones
   * @param value
   * @returns
   */
  getGuideByAnnotations(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchAnnotation'), value
      , { headers });
  }


  /**
   * Servicio de peticion busqueda por numero de lockers
   * @param value
   * @returns
   */
  getUserNumberLocker(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchNumberLocker'), value
      , { headers });
  }

  /**
   * Servicio de peticion busqueda por WR
   * @param value
   * @returns
   */
  getUserWR(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchWR'), value
      , { headers });
  }

  /**
   * Servicio de peticion busqueda por Tracking
   * @param value
   * @returns
   */
  getUserTracking(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchTracking'), value
      , { headers });
  }

  /**
   * Servicio de peticion busqueda por numero de consolidado
   * @param value
   * @returns
   */
  getConsolidateByNumber(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchConsolidated'), value
      , { headers });
  }

  /**
   * Servicio de peticion busqueda por AWB
   * @param value
   * @returns
   */
  getConsolidateByAWB(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchAWB'), value
      , { headers });
  }

  /**
   *  Servicio de peticion busqueda por email
   * @param value
   * @returns
   */
  getUserEmailGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/globalSearchEmail'), value
      , { headers });
  }

  getAllNumberGuidesByUser(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/getAllNumberGuidesByUser'),
      data, { headers });
  }
}
