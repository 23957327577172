import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, Form, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { AlertMessageService } from '../../services/alert-message.service';

@Component({
  selector: 'app-upload-pictures',
  templateUrl: './upload-pictures.component.html',
  styleUrls: ['./upload-pictures.component.scss']
})
export class UploadPicturesComponent implements OnInit {

  packagePictures: Array<any> = new Array();
  packagePicture: FormControl;
  @Output() filesToSubmit = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private alertMessageService: AlertMessageService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.packagePicture = new FormControl();
  }

  submitFiles() {
    this.filesToSubmit.emit(this.packagePictures);
  }

  removeFiles() {
    this.packagePictures = null;
    this.initForm();
    this.submitFiles();
  }

  removeFile(file) {
    this.packagePictures.splice(this.packagePictures.indexOf(file), 1);
    this.submitFiles();
  }

 
  receiptLoad($event) {
    //this.packagePictures = null;
    let maxSizeFile = 5000000;
    let language = this.languageService.getCurrentLanguage();
    const filesArray =[];
    if ($event.target.files.length >= 1) {
      let files = $event.target.files;
      for (let i = 0; i < files.length; i++) {

        if(files[i].type == 'image/gif' &&  files[i].size >  3500000 ){
          this.alertMessageService.showErrorMessage(language, "EFTGIF");
        }
        else if (files[i].size > maxSizeFile) {
          this.alertMessageService.showErrorMessage(language, "EFT")
        }
        else if (!['image/jpeg', 'image/png', 'image/gif', 'application/pdf'].includes(files[i].type)) {
          this.alertMessageService.showErrorMessage(language, "TPF");
        }
        
        else 
        {
          filesArray.push(files[i]);
        }


      }
      filesArray.forEach(file => {
        if (!this.packagePictures.includes(file)) {
          this.packagePictures.push(file);
        }
      });
    }
    this.submitFiles();
  }

}
