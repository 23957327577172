import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SeekerComponent } from './seeker/seeker.component';
import { InputSearchComponent } from '../forms/input-search/input-search.component';
import { CarouselNavigationComponent } from './carousel-navigation/carousel-navigation.component';
import { GraphicComponent } from './graphic/graphic.component';
import { ChartsModule } from 'ng2-charts-x';
import { SharedCalculatorComponent } from '../shared-calculator/shared-calculator.component';



@NgModule({
  exports: [
    SeekerComponent,
    InputSearchComponent,
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CarouselNavigationComponent,
    ToggleFullscreenDirective,
    NgbModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    GraphicComponent,
    SharedCalculatorComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    NgbModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ToggleFullscreenDirective,
    SeekerComponent,
    InputSearchComponent,
    CarouselNavigationComponent,
    GraphicComponent,
    SharedCalculatorComponent
  ]
})
export class SharedModule { }
