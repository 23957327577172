
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { AlertMessageService } from '../../services/alert-message.service';
import { WrService } from '../../services/wr.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-search-wr',
  templateUrl: './search-wr.component.html',
  styleUrls: ['./search-wr.component.scss']
})
export class SearchWrComponent implements OnInit {

  foundInfoOfWR: Array<any>;
  selectedWR: any;
  selectedWRTemp: any;
  contentIndexToDelete: number;
  searchWRForm: FormGroup;
  initialSelection = [];
  selection : any;
  dataSource = [];

  

  @Output() WRSelected = new EventEmitter<any>();
  @Input() showClosed: any;
  constructor(private languageService: LanguageService,
    private alertMessageService: AlertMessageService,
    private wrService: WrService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal) { 



    }

  ngOnInit() {
    this.selection = new SelectionModel<any>(true, this.initialSelection);
    this.initFormSearchWR();
    this.reset();
  }

  initFormSearchWR() {
    this.searchWRForm = this.formBuilder.group({
      'value': ["", Validators.compose([])]
    });
  }

  reset() {
    this.foundInfoOfWR = [];
    this.selectedWR = null;
    this.selectedWRTemp = null;
  }

  searchWR() {
    this.reset();
    let data = {
      value: this.searchWRForm.value['value'],
      showClosed: this.showClosed
    };
    let language = this.languageService.getCurrentLanguage();
    this.wrService.searchWR(data).subscribe(result => {
      this.foundInfoOfWR = result['wrs'];
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    });
  }

  selectedWREvent() {
    this.WRSelected.emit(this.selection.selected);
    this.selection = new SelectionModel<any>(true, this.initialSelection);
  }

  openModalSearchWR(modal) {
    this.modalService.open(modal, { size: 'lg' });
  }

  changeSelectedWR(wr, radio){
    this.selectedWRTemp = wr;
    radio.checked = true;
  }




isTheSameUser(row){
  if(this.selection.selected.length > 0){
    if(this.selection.selected[0].id == row.id){
      return true;
    }else{
      this.selection.clear();
      return false;
    }
  }else{
    return true;
  }

}

}
