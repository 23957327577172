import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ListCarriersService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getCarriers(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    if (token) {
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.get(this.configService.serverIp.concat('/listCarriers'), { headers });
    } else {
      return this.http.get(this.configService.serverIp.concat('/listCarriers'));
    }

  }

  getCarrier(carrierId: string): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('id_carrier', carrierId);
    return this.http.get(this.configService.serverIp.concat('/getCarrier'), { headers, params });
  }

  editCarrier(carrier): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      id_carrier: carrier.carrier_id,
      carrierCode: carrier.carrierCode,
      carriername: carrier.carrierName,
      agreement: carrier.agreement,
      country_id: carrier.country_id,
      cost: carrier.cost,
      requiredFields: carrier.requiredFields,
      documentProperties: carrier.documentProperties
    };
    return this.http.post(this.configService.serverIp.concat('/editCarrier'), data, { headers });
  }
  /*
    deleteCarrier(carrier): Observable<any> {//<-- No se usa
      const token = localStorage.getItem('currentUser');
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.post(this.configService.serverIp.concat('/deleteCarrier'), carrier, {headers});
    }*/

  deactivateCarrier(carrier): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/deactivateCarrier'), carrier, { headers });
  }

  activateCarrier(carrier): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/activateCarrier'), carrier, { headers });
  }

  addCarrierCostByCity(data): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/addCarrierCostByCity'), data, { headers });
  }

  updateCarrierCostByCity(data): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/updateCarrierCostByCity'), data, { headers });
  }

  deleteCarrierCostByCity(data): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/deleteCarrierCostByCity'), data, { headers });
  }
}
