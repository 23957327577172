import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidateAccountService } from '../../services/validate-account.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertMessageService } from '../../services/alert-message.service';
import { matchOtherValidator } from '../../validators/matchOther.validator';
import { sha256 } from 'js-sha256';
import { RegisterService } from '../../services/register.service';

@Component({
  selector: 'app-validate-account-with-password',
  templateUrl: './validate-account-with-password.component.html',
  styleUrls: ['./validate-account-with-password.component.scss']
})
export class ValidateAccountWithPasswordComponent implements OnInit {

  token: any;
  passwordForm: FormGroup;
  userAgreement: String;
  logoURL:string;
  constructor(private translate: TranslateService,
  private languageService: LanguageService,
  private route: ActivatedRoute,
  private validateAccountService: ValidateAccountService,
  private formBuilder: FormBuilder,
  private alertMessageService: AlertMessageService,
  private router: Router,
  private registerService: RegisterService) {
    this.languageService.initLanguageConfig();
    this.translate.use(this.languageService.getCurrentLanguage());
  }

  ngOnInit() {
    this.getUserAgreement();
    this.route.params.subscribe(params => {
      this.token = params['hash'];
    });
    this.initForm();
  }

  getUserAgreement(){
    const language = this.languageService.getCurrentLanguage();
    this.registerService.getCountries().subscribe(result => {
      this.userAgreement = result['useragreement'];
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    }
  );
  }

  initForm(){
    this.passwordForm = this.formBuilder.group({
      'validate': [this.token],
      'password': ['', Validators.compose([Validators.required])],
      'repeatPassword': ['', Validators.compose([Validators.required, matchOtherValidator('password')])],
      'userAgreement': [false, Validators.compose([Validators.requiredTrue])],
    });
  }

  getFlagURL():string{
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

  // Método para cambiar el lenguaje de la aplicación
  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  activateAccountWithPassword(){
    const values = this.passwordForm.value;
    const data = {
      validate: values.validate,
      password: sha256(values.password)
    };
    const language = this.languageService.getCurrentLanguage();
    this.validateAccountService.validateAccountWithPassword(data).subscribe(result => {
      ////console.log(result);
      if (result['status']) {
        this.alertMessageService.showSuccessMessage(language, result['code']);
        this.router.navigate(['/login']);
      } else {
        this.alertMessageService.showErrorMessage(language, result['code']);
      }
    }, error => {
      this.alertMessageService.showErrorMessage(language, 'CE');
    });
  }

}
