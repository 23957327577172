import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatchHeightModule} from '../../shared/directives/match-height.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SearchWrComponent } from '../search-wr/search-wr.component';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    MatchHeightModule],
  exports: [
    SearchWrComponent
  ],
  declarations: [
    SearchWrComponent
  ],
  providers: []
})

export class SearchWrModule {}

