import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './config.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  configService = null;

  constructor(private http: HttpClient) {
    this.configService = new ConfigService();
  }

  login(loginData) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.configService.serverIp.concat('/login'), loginData, {headers});
  }


  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenInfo');
  }

  forgotPassword(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.configService.serverIp.concat('/forgotPassword'), data, {headers});
  }
}
