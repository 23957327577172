import { Component, Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-carousel-navigation',
  templateUrl: './carousel-navigation.component.html',
  styleUrls: ['./carousel-navigation.component.scss']
})
export class CarouselNavigationComponent {
  @Input() users: {};
  guideTable: any = null;
  hideGuides = true;
  showNavigationArrows: boolean;
  showNavigationIndicators: boolean;

  constructor(config: NgbCarouselConfig) {

    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    localStorage.setItem('guides', 'hide');

  }

  showGuides(currentGuides: any) {
    localStorage.setItem('guides', 'show');
    const list: Array<any> = new Array();
    if (currentGuides != undefined) {
      for (let i = 0; i < currentGuides.length; i++) {
        const data = {
          'guide': currentGuides[i].guide,
          'total': currentGuides[i].invoice ? currentGuides[i].invoice.total : 0
        };
        list.push(data);
      }
      this.guideTable = new MatTableDataSource(list);
    }
  }

  getGuides(){
    return localStorage.getItem("guides");
  }

  hideGuidess(){
    localStorage.setItem('guides', 'hide');
  }

  validateProfile(user){
    // if(user.numCasillero){
    //   return true;
    // }
    // else{
    //   return false;
    // }
    let userDCV = false;
   for (let i = 0; i < user.roles.length; i++) {
     if (user.roles[i].name == 'DCV') {
       userDCV = true;
     }
   }
    return userDCV;
  }
}
