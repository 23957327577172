import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { matchOtherValidator } from "../../validators/matchOther.validator";
import { RegisterService } from "../../services/register.service";
import { sha256 } from 'node_modules/js-sha256';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertMessageService } from '../../services/alert-message.service';
import { MultilingualHeaderImageService } from '../../services/multilingual-header-image.service';
import { LanguageService } from '../../services/language.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss',
    '../../../assets/css/form-elements.css',
    '../../../assets/css/style.css'
  ]
})
export class RegisterComponent implements OnInit {
  user: User;
  countries = null;
  registerForm: FormGroup;
  countrySelected = null;
  states = null;
  stateSelected = null;
  cities = null;
  language: String;
  logoURL: string;
  userAgreement: String;
  viewPass: string;
  password: string;
  repeatPass: string;
  viewRepass: string;
  captcha: boolean;
  typeDocument: number;
  source = '';
  other: string;

  titleSwal: any;
  textSwal: any;


  constructor(private formBuilder: FormBuilder,
    private registerService: RegisterService,
    public translate: TranslateService,
    private router: Router,
    private alertMessageService: AlertMessageService,
    private languageService: LanguageService,
    private multilingualHeadersService: MultilingualHeaderImageService) {
    let browserLang: string = translate.getBrowserLang();
    browserLang = browserLang.match(/en|es/) ? browserLang : 'en';
    translate.use(browserLang);
    this.language = browserLang;
    localStorage.setItem('language', browserLang);
    this.user = new User();
    this.setCountries();
    this.password = "password";
    this.viewPass = "textShow";
    this.repeatPass = "password";
    this.viewRepass = "textShow";
    this.captcha = false;
    this.typeDocument = 3;
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse != "") {
      this.captcha = true;
    }
    this.registerService.recaptchaValidation(captchaResponse).subscribe(result => {
      // console.log(result);
      // if(result['success'] == true){
      //   this.captcha = true;
      // }
    });
  }

  // Método para cambiar el lenguaje de la aplicación
  ChangeLanguage(language: string) {
    this.translate.use(language);
    this.language = language;
    localStorage.setItem('language', language);
  }

  ngOnInit() {
    this.validateForm();
  }

  translateText(textToTranslate) {
    let dataTranslated = "";
    this.languageService.getTranslateOf(textToTranslate).subscribe(x => {
      dataTranslated = x
    });

    return dataTranslated;
  }

  openSwal() {
    this.titleSwal = this.translateText("requestshippingPayment.success");
    this.textSwal = this.translateText("validateAccount.successRegister");
    Swal.fire(this.titleSwal, this.textSwal, 'success');
  }

  validateForm() {
    let stringRegularExpression = "([á*é*í*ó*ú*Á*É*Í*Ó*Ú*]*[A-z]*[\\s]*)+";
    this.registerForm = this.formBuilder.group({
      'firstName': [this.user.firstname, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern(stringRegularExpression)])],
      'lastName': [this.user.lastname, Validators.compose([Validators.required, Validators.minLength(3), Validators.pattern(stringRegularExpression)])],
      'document': [this.user.document],
      'phone': [this.user.phone, Validators.required],
      'country': [this.user.country_id, Validators.required],
      'state': [this.user.state_id, Validators.required],
      'city': [this.user.city_id, Validators.required],
      'zip': [this.user.zipcode],
      'email': [this.user.email, Validators.compose([Validators.required, Validators.email])],
      'repeatEmail': ['', Validators.compose([Validators.required, Validators.email, matchOtherValidator('email')])],
      'password': [this.user.password, Validators.compose([Validators.required])],
      'repeatPassword': ['', Validators.compose([Validators.required, matchOtherValidator('password')])],
      'userAgreement': [false, Validators.compose([Validators.required, Validators.requiredTrue])],
      'userDataAgreement': [false],
      'address': ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      'source': [this.source, [Validators.required, Validators.min(1)]],
      'other': [this.other],
    });
  }

  addData(post) {
    this.user.firstname = post.firstName;
    this.user.lastname = post.lastName;
    this.user.document = post.document;
    this.user.phone = post.phone;
    this.user.country_id = post.country * 1;
    this.user.state_id = post.state * 1;
    this.user.city_id = post.city * 1;
    this.user.zipcode = post.zip * 1;
    this.user.email = post.email;
    this.user.password = sha256(post.password);
    this.user.address = post.address;
    this.user.agreement = post.userAgreement;
    this.user.dataAgreement = post.userDataAgreement;
    this.user.email = this.user.email.trim().toLowerCase();
    if (this.registerForm.controls['source'].value === 'other' || 
    ((this.registerForm.controls['source'].value === 'friend') && this.registerForm.controls['other'].value.length > 0)) {
      this.registerForm.controls['source'].setValue(this.registerForm.controls['other'].value.trim(), { emitEvent: false });
    }
    this.user.findOut = this.registerForm.controls['source'].value;
    this.registerService.postRegister(this.user).subscribe(result => {
      const messageCode = result['code'];
      if (result['status'] === true) {
        this.openSwal();
        this.router.navigate(['/login']);
        this.alertMessageService.showLongSuccessMessage(this.language, messageCode);
      } else {
        this.alertMessageService.showErrorMessage(this.language, messageCode);
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    }
    );
  }

  validateAboutYou() {
    const findOutValidation = this.registerForm.controls['source'].valid && this.registerForm.controls['other'].valid;
    if (this.typeDocument == 3) {
      return this.registerForm.controls['firstName'].valid && this.registerForm.controls['lastName'].valid && this.registerForm.controls['phone'].valid && findOutValidation;
    }
    else {
      return this.registerForm.controls['firstName'].valid && this.registerForm.controls['lastName'].valid && this.registerForm.controls['document'].valid && this.registerForm.controls['phone'].valid && findOutValidation;
    }
  }

  validateLocation() {
    return this.registerForm.controls['country'].valid && this.registerForm.controls['state'].valid && this.registerForm.controls['city'].valid && this.registerForm.controls['address'].valid && this.validateIDLocation();
  }


  validateIDLocation() {
    return (this.registerForm.controls['country'].value != 0) && (this.registerForm.controls['state'].value != 0) && (this.registerForm.controls['city'].value != 0);
  }
  validateAcount() {
    return this.registerForm.controls['email'].valid && this.registerForm.controls['repeatEmail'].valid && this.registerForm.controls['password'].valid && this.registerForm.controls['repeatPassword'].valid && this.registerForm.controls['userAgreement'].valid && this.captcha;
  }

  validateTypeDocument() {
    if (this.typeDocument == 3) {
      return true;
    }
    else {
      return false;
    }
  }

  setCountries() {
    this.registerService.getCountries().subscribe(result => {
      this.countries = result['countries'];
      this.logoURL = result['logo'];
      this.userAgreement = result['useragreement'];
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    }
    );
  }

  setStates(countryId) {
    this.registerService.getStatesByCountry(countryId).subscribe(result => {
      this.states = result['states'];
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    }
    );
  }

  setCities(stateId) {
    this.registerService.getCitiesByState(stateId).subscribe(result => {

      this.cities = result['cities'];
      if (this.cities.length > 0) {
        this.registerForm.controls['city'].setValue(this.cities[0].id * 1); // full 
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    }
    );
  }

  findOut() {
    if (this.registerForm.controls['source'].value === 'other') {
      this.registerForm.controls['other'].setValidators([Validators.required]);
    } else {
      this.registerForm.controls['other'].clearValidators();
    }
    this.registerForm.controls['other'].updateValueAndValidity();
  }

  onChangeCountry($event) {
    this.setStates(this.countrySelected);
  }

  onChangeState($event) {
    this.setCities(this.stateSelected);
  }

  getHeaderURL() {
    const language = this.languageService.getCurrentLanguage();
    return this.multilingualHeadersService.getHeaderURL('15', language);
  }

  getFlagURL(): string {
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

  changePrivateKeyInputPass() {
    if (this.password == "text") {
      this.password = "password";
      this.viewPass = "textShow";
    } else {
      this.password = "text";
      this.viewPass = "textHide";
    }
  }


  changePrivateKeyInputRepeatPass() {
    if (this.repeatPass == "text") {
      this.repeatPass = "password";
      this.viewRepass = "textShow";
    } else {
      this.repeatPass = "text";
      this.viewRepass = "textHide";
    }
  }

}
