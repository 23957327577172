import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }


  prievateGetResult(input): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const data = {
      'declaredPrice': input['declaredPrice'],
      'weight': input['weight'],
      'carrier_id': input['carrier'],
      'service_id': input['service'],
      'dutytax_id': input['dutytax'],
      'paymentMethod': input['paymentMethod'],
      'width': input['width'],
      'height': input['height'],
      'length': input['length'],
    };
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/privateCalculateCost'), data, { headers });
  }


  getServicesOfAgencyByCountryForCalculator(country_id) {
    const params = new HttpParams().set('country_id', country_id);
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getServicesOfAgencyByCountryForCalculator'), { headers, params });

  }

  getResult(input): Observable<any> {


    const data = {
      'declaredPrice': input['declaredPrice'],
      'weight': input['weight'],
      'height': input['height'],
      'width': input['width'],
      'length': input['length'],
      'carrier_id': input['carrier'],
      'service_id': input['service'],
      'dutytax_id': input['dutytax'],
      'paymentMethod': input['paymentMethod']
    };

    console.log(data);

    const token = localStorage.getItem('currentUser');
    if (token) {
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.post(this.configService.serverIp.concat('/publicCalculateCost'), data, { headers });
    }
    else {
      return this.http.post(this.configService.serverIp.concat('/publicCalculateCost'), data);
    }
  }
}
