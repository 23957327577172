import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ValueTransformer } from '@angular/compiler/src/util';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  @Input() inputClass: String;
  @Input() matLabel: String;
  @Input() hover: boolean;
  @Input() index: number;
  @Output() sendValues = new EventEmitter();
  @Output() onEnter = new EventEmitter();


  values = {
    'name': '',
    'value': ''
  };
  constructor() { }

  ngOnInit() {
  }

  lanzar(name) {
    this.values['name'] = name;
    // Usamos el método emit
    this.sendValues.emit({ values: this.values });
  }

  enter() {
    this.onEnter.emit({ values: true });
  }
}
