import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MultilingualHeaderImageService {

  private headerURLs = {
    '1': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/1es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/1en.jpg'
    },
    '2': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/2es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/2en.jpg'
    },
    '3': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/3es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/3en.jpg'
    },
    '4': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/4es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/4en.jpg'
    },
    '5': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/5es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/5en.jpg'
    },
    '6': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/6es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/6en.jpg'
    },
    '7': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/7es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/7en.jpg'
    },
    '8': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/8es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/8en.jpg'
    },
    '9': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/9es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/9en.jpg'
    },
    '10': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/10es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/10en.jpg'
    },
    '11': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/11es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/11en.jpg'
    },
    '12': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/12es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/12en.jpg'
    },
    '13': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/13es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/13en.jpg'
    },
    '14': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/14es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/14en.jpg'
    },
    '15': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/15es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/15en.jpg'
    },
    '16': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/16es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/16en.jpg'
    },
    '17': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/17es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/17en.jpg'
    },
    '18': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/18es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/18en.jpg'
    },
    '20': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/20es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/20en.jpg'
    },
    '21': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/21es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/21en.jpg'
    },
    '22': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/22es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/22en.jpg'
    },
    '23': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/23es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/23en.jpg'
    },
    '24': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/24es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/24en.jpg'
    },
    '25': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/25es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/25en.jpg'
    },
    '26': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/26es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/26en.jpg'
    },
    '27': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/27es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/27en.jpg'
    },
    '28': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/28es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/28en.jpg'
    },
    '29': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/29es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/29en.jpg'
    },
    '30': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/30es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/30en.jpg'
    },
    '31': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/31es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/31en.jpg'
    },
    '32': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/32es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/32en.jpg'
    },
    '33': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/33es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/33en.jpg'
    },
    '34': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/34es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/34en.jpg'
    },
    '35': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/35es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/35en.jpg'
    },
    '36': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/36es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/36en.jpg'
    },
    '37': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/37es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/37en.jpg'
    },
    '38': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/38es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/38en.jpg'
    },
    '39': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/39es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/39en.jpg'
    },
    '40': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/40es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/40en.jpg'
    },
    '41': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/41es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/41en.jpg'
    },
    '42': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/42es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/42en.jpg'
    },
    '43': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/43es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/43en.jpg'
    },
    '44': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/44es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/44en.jpg'
    },
    '45': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/45es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/45en.jpg'
    },
    '46': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/46es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/46en.jpg'
    },
    '47': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/47es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/47en.jpg'
    },
    '48': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/48es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/48en.jpg'
    },
    '49': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/49es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/49en.jpg'
    },
    '50': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/50es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/50en.jpg'
    },
    '51': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/51es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/51en.jpg'
    },
    '52': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/52es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/52en.jpg'
    },
    '53': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/53es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/53en.jpg'
    },
    '54': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/54es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/54en.jpg'
    },
    '55': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/55es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/55en.jpg'
    },
    '56': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/56es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/56en.jpg'
    },
    '57': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/57es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/57en.jpg'
    },
    '58': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/58es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/58en.jpg'
    },
    '59': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/59es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/59en.jpg'
    },
    '60': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/60es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/60en.jpg'
    },
    '61': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/61es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/61en.jpg'
    },
    '62': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/62es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/62en.jpg'
    },
    '63': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/63es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/63en.jpg'
    },
    '68': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/68es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/68en.jpg'
    },
    'login':{
      'es':'../../../assets/img/login/imagen-loginES.jpg',
      'en':'../../../assets/img/login/imagen-loginEN.jpg'
    },
    '69': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/69es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/69en.jpg'
    },
    '70': {
      'es': 'https://publiclogisticscargo.s3.amazonaws.com/headers/70es.jpg',
      'en': 'https://publiclogisticscargo.s3.amazonaws.com/headers/70en.jpg'
    },
  };

  constructor(public translate: TranslateService) { }

  initLanguageConfig(){
    let language:string = localStorage.getItem('language');
    if(!language){
      language = this.translate.getBrowserLang();
      language = language.match(/en|es/) ? language : 'en';
    }
    this.changeLanguage(language);
  }

  // Método para cambiar el lenguaje de la aplicación
  changeLanguage(language:string){
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  getHeaderURL(imageId, language) {
    return this.headerURLs[imageId][language];
  }
}
