import { Component } from '@angular/core';
import { ThemeService } from './services/theme.service';
import { CompanyInfoService } from './services/company-info.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  title = 'app';
  router: string;
  currentUser: any;
  content_routes: any;
  pru = false;
  constructor(private _router: Router) {
    this.router = _router.url;
  }

  showSearch() {
    return this._router.url != '/login' && this._router.url != '/register'
      && this._router.url != '/forgot-password' && this._router.url != '/register-company-info'
      && this._router.url != '/search-guide-status';
  }



}
