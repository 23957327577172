import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, Observable} from 'rxjs';
import { DataSource } from '@angular/cdk/table';

@Injectable()
export class AuthService {
  token: string;
  private permissionsSubject = new BehaviorSubject([]);
  permissions = this.permissionsSubject.asObservable();
  constructor(private router: Router) {
    if (localStorage.getItem('currentUser') && localStorage.getItem('tokenInfo')  ) {
      this.setUrlPermissions();
    }
    // this.urlPermissions.push('home');
  }
  getPermissions() {
    return this.permissionsSubject.asObservable();
  }
  private refreshPermissions(permissions) {
    this.permissionsSubject.next(permissions);
  }

  setUrlPermissions() {
    const urls = [];
    //const token = localStorage.getItem('currentUser');
    //const helper = new JwtHelperService();
    //const decodedToken = helper.decodeToken(token);
    //const paths: any = decodedToken.urls;

    const tokenInfo = localStorage.getItem('tokenInfo');
    const helperInfo = new JwtHelperService();
    const decodedTokenInfo = helperInfo.decodeToken(tokenInfo);
    const pathsInfo: any = decodedTokenInfo.urls;
    pathsInfo.forEach(function (path) {
      urls.push(path);
    });

    this.refreshPermissions(urls);
  }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    // your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    this.token = null;
  }

  getToken() {
    return this.token;
  }

  // Este método se encarga de revisar si hay un usuario con una sesión activa.
  // Para determinar lo anterior se revisa que haya un token sin expiración y que
  // en el local storage haya un current user almacenado. Si no hay una sesión activa 
  // se redirecciona a Login

  isAuthenticated() {
    const expDate = this.getExpirationDateToken();
    const today = new Date();
    if (today > expDate || !localStorage.getItem('currentUser')) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

  getExpirationDateToken() {
    const token = localStorage.getItem('currentUser');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    if (decodedToken && decodedToken.exp) {
      const date = new Date(decodedToken.exp * 1000);
      return date;
    }
    return new Date();
  }



}
