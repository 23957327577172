import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class WrService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  searchWR(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/searchWRByValue'), data, { headers });
  }

  registerWR(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/addWRPackage'), data, { headers });
  }

  WRToAWB(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/WRToAWB'), data, { headers });
  }
}
