import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';

import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';

import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {CONTENT_ROUTES} from './shared/routes/content-layout.routes';

import {AuthGuard} from './shared/auth/auth-guard.service';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {AuthGuardChildService} from './shared/auth/auth-guard-child.service';

// Para encontrar la página que se carga por defecto revisar
// el método canActivate del auth-guard.service. Allí se define si
// se puede acceder a otras rutas o se redirecciona a Login. Esto se
// define teniendo en cuenta si hay un usuario con una sesión activa.

const appRoutes: Routes = [
  {
    // Reirección del router outlet de fullLayoutComponent hacia home
    // cuando un usuario tiene una sesión activa
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    // Ruta por defecto condicionada a canActivate de auth-guard.service
    // canActivate retorna true si hay un usuario con sesión activa, es decir,
    // si hay un curren user almacenado en local storage. De lo contrario el
    // método canActivate del auth.service redirecciona a /Login para hacer 
    // efectiva la renderización del componente correspondiente en CONTENT_ROUTES 
    // que está activado sin condiciones de sesión.
    
    path: '',
    component: FullLayoutComponent,
    data: {title: 'full Views'},
    children: Full_ROUTES,
    canActivate: [AuthGuard],
    canActivateChild : [AuthGuardChildService]
  },
  {
    // Ruta por defecto sin condiciones
    path: '',
    component: ContentLayoutComponent,
    data: {title: 'content Views'},
    children: CONTENT_ROUTES,
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
