import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardChildService implements CanActivateChild {
  permissions = [];

  constructor(private authService: AuthService, private router: Router) {
    this.authService.permissions.subscribe(permissions => this.permissions = permissions);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.authService.isAuthenticated()) {
      return false;
    }

    let result = false;
    try {
      // result = this.authService.menuPermissions(route.url[0]);
      result = this.validatePath(route.url[0]);
      if (!result) {
        this.router.navigate(['/home']);
      }

    } catch (e) {
      //// console.log(e);
    }
    return result;
  }

  validatePath(urlObject) {
    let result = false;
    if (urlObject) {
      if (urlObject.path === 'home') {
        result = true;
      } else {
        result = this.permissions.includes('/' + urlObject.path);
      }
    } else if (urlObject === undefined) {
      result = true;
    }
    return result;
  }
}
