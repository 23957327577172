import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  constructor(public translate: TranslateService) {}

  // Método para inicializar el lenguaje
  initLanguageConfig() {
    let language: string = localStorage.getItem('language');
    if (!language) {
      // TranslateService es un servicio de node modules con funcionalidades
      // útiles para traducciones
      language = this.translate.getBrowserLang();
      language = language.match(/en|es/) ? language : 'en';
    }
    this.changeLanguage(language);
  }

  // Método para cambiar el lenguaje de la aplicación
  changeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  getCurrentLanguage(): string {
    return localStorage.getItem('language');
  }

  getLanguageFlagURL(language: string): string {
    const usURL = 'assets/img/flags/us.png';
    const esURL = 'assets/img/flags/es.png';

    if (language === 'es') {
      return esURL;
    }
    if (language === 'en') {
      return usURL;
    }
  }

  getTranslateOf(text: string) {
    return this.translate.get(text);
  }
}
