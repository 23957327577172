import {Component, OnInit, HostBinding, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../auth/auth.service'
import { MenuService } from '../../services/menu.service';
import { LanguageService } from '../../services/language.service';
import { IconSideBarService } from '../../services/icon-side-bar.service';
export const ROUTES = [];
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  @Input() logoURL: String;

  public menuItems: any[];

  constructor(private router: Router,
    private authService: AuthService,
    private menuService: MenuService,
    private iconSideBarService: IconSideBarService) {
  }

  ngOnInit() {
    // Script con jquery que define el comportamiento gráfico de los items del sidebar
    $.getScript('./assets/js/app-sidebar.js');

    this.authService.setUrlPermissions();

    // Envía permisos para las url que llegan
    this.menuService.getMenuObservable().subscribe(menus => {
      this.menuItems = menus;
    });

    // Se cargan los items del menú correspondientes al rol activo
    this.menuService.loadMenus();

    // Se carga el logo
    this.logoURL = localStorage.getItem('logoURL');

    this.iconSideBarService.change.subscribe(imagen => {
      this.logoURL = imagen;
    });
  }
}
