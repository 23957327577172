import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateAgencyOperatorService {
  language;
  constructor() { }

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggle(languaje) {
    this.language = languaje;
    this.change.emit(this.language);
  }
}
