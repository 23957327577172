import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './config.service';


@Injectable({
  providedIn: 'root'
})
export class ValidateAccountService {
  configService = null;

  constructor(private http: HttpClient) {
    this.configService = new ConfigService();
  }

  validate(hashData) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.configService.serverIp.concat('/validateAccount'), hashData, {headers});
  }

  validateAccountWithPassword(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.configService.serverIp.concat('/validateAccountWithPassword'), data, {headers});
  }

  changeValidateAccount(data){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': token
      });
   
    return this.http.post(this.configService.serverIp.concat('/changeValidateAccount'), data, {headers});
  }
}
