import { Component, OnInit } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { JwtHelperService } from '@auth0/angular-jwt';
import { InputSearchComponent } from 'src/app/forms/input-search/input-search.component';
import { GlobalSearchService } from 'src/app/services/global-search.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material';
import { AlertMessageService } from 'src/app/services/alert-message.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-seeker',
  templateUrl: './seeker.component.html',
  styleUrls: ['./seeker.component.scss']
})
export class SeekerComponent implements OnInit {
  roles: any;
  hover: any;
  completedInputs = ['numberGuide', 'name', 'numberLocker', 'WR', 'phone', 'annotations'];
  inputs: any;
  inputValue: any;
  inputIndex: any;
  public guideTable: any;
  public users: any;
  language;
  constructor(
    private globalSearchService: GlobalSearchService,
    private router: Router,
    private modalService: NgbModal,
    private languageService: LanguageService,
    private alertMessageService: AlertMessageService,
  ) {
    this.language = this.languageService.getCurrentLanguage();
   }


  ngOnInit() {
    this.getUserInformation();
  }
  changeHover() {
    this.hover = !this.hover;
  }
  getUserInformation() {
    const token = localStorage.getItem('currentUser');
    if (token != null) {
      //const helper = new JwtHelperService();
     // const decodedToken = helper.decodeToken(token);
      const tokenInfo = localStorage.getItem('tokenInfo');
      const helperInfo = new JwtHelperService();
      const decodedTokenInfo = helperInfo.decodeToken(tokenInfo);
      this.roles = decodedTokenInfo.roles;
     // this.roles = decodedToken.roles[0];
      if (this.roles.includes('OPAG')) {
        this.inputs = ['numberGuide', 'name', 'phone'];
      } else {
        this.inputs = this.completedInputs;
      }
    }
  }
  showValues(event): void {
    this.inputValue = event.values;
  }


  // /**
  //  * Metodo auxiliar  de busqueda temporal mientras se realiza la constuccion de la nueva vista
  //  */
  // globalSearchAux(content) {
  //   this.inputValue['value'] = this.inputValue['value'].toUpperCase();
  //   if (this.inputValue.name === 'numberGuide'  || this.inputValue.name === 'annotations') {
  //     this.globalSearchService.getGuide(this.inputValue).subscribe(result => {
  //       if(result['status']){
  //         let list = []
  //         let currentGuides = result['guides'];
  //         if (currentGuides != undefined) {
  //           for (let i = 0; i < currentGuides.length; i++) {
  //             const data = {
  //               'guide': currentGuides[i].guide,
  //               'sender': currentGuides[i].nameFrom,
  //               'receiver': currentGuides[i].nameTo,
  //               'weight': currentGuides[i].weight ? currentGuides[i].weight : 0,
  //               'currentStatus': currentGuides[i].currentStatus ? currentGuides[i].currentStatus : 0,
  //               'annotations' : currentGuides[i].annotations ? currentGuides[i].annotations : "",
  //               'status': currentGuides[i].currentStatus ? currentGuides[i].currentStatus : "",
  //             };
  //             list.push(data);
  //           }
  //         }
  //         this.guideTable = new MatTableDataSource(list)
  //         this.openModal(content, 'dark-modal');
  //       }else{
  //         this.alertMessageService.showErrorMessage(this.language, result['message']);
  //       }
  //       this.changeHover();
  //     });
  //   }else if (this.inputValue.name === 'name' || this.inputValue.name === 'phone'  || this.inputValue.name === 'numberLocker' ) {
  //     this.globalSearchService.getUser(this.inputValue).subscribe(result => {
  //       if(result['status']){
  //         this.users = result['users'];
  //         if (this.users.length > 0) {
  //           this.openModal(content, 'dark-modal');
  //         }else {
  //           this.alertMessageService.showInfoMessage(this.language, 'NG');
  //         }
  //       }else{
  //         this.alertMessageService.showErrorMessage(this.language, result['message']);
  //       }
  //       this.changeHover();
  //     });
  //   }
  //   this.inputValue['value'] = '';
  // }
  globalSearch(content) {
    console.log(this.inputValue);
    console.log('entro aca ');
    this.inputValue['value'] = this.inputValue['value'].toUpperCase();
    this.globalSearchService.getGuide(this.inputValue).subscribe(result => {
      if(result['status']){
        if (this.inputValue.name === 'numberGuide'  || this.inputValue.name === 'annotations') {
          let list = []
          let currentGuides = result['guides'];
          if (currentGuides != undefined) {
            for (let i = 0; i < currentGuides.length; i++) {
              let statusGuide=""+currentGuides[i].status[currentGuides[i].status.length-1].status;
              let notesStatus= currentGuides[i].status[currentGuides[i].status.length-1].notes;
              const data = {
                'guide': currentGuides[i].guide,
                'sender': currentGuides[i].fromAddress.name,
                'receiver': currentGuides[i].toAddress.name,
                'weight': currentGuides[i].weight ? currentGuides[i].weight : 0,
                'annotations' : currentGuides[i].annotations ? currentGuides[i].annotations : "",
                'status': statusGuide+" "+(notesStatus ? notesStatus: "")
              };
              list.push(data);
            }
          }
          this.guideTable = new MatTableDataSource(list)
          this.openModal(content, 'dark-modal');
        } else if (this.inputValue.name === 'name' || this.inputValue.name === 'phone'  || this.inputValue.name === 'numberLocker' ) {
          this.users = result['users'];
          if (this.users.length > 0) {
            this.openModal(content, 'dark-modal');
          }else {
            this.alertMessageService.showInfoMessage(this.language, 'NG');
          }
        }
      }else{
        this.alertMessageService.showErrorMessage(this.language, result['message']);
      }
      this.changeHover();
    });
    this.inputValue['value'] = '';
  }


  openModal(modal, modalClass?: string) {

    if(modalClass !== undefined){
      this.modalService.open(modal, { size: 'lg', windowClass: 'dark-modal'});
    }else{
      this.modalService.open(modal, {windowClass: 'large-modal'});
    }
  }

  enter(event) {
    if (event.values === true) {
      this.globalSearch(document.getElementById('showInfo'));
    }
  }
}
