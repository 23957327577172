import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { trigger, state, animate, transition, style } from '@angular/animations';


@Component({
  selector: 'app-graphic',
  templateUrl: './graphic.component.html',
  styleUrls: ['./graphic.component.scss'],
  animations: [
    trigger('changeState', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in')
      ])
    ])
  ]
})
export class GraphicComponent implements OnInit {
  @Input() chartLabels;
  @Input() chartData;
  @Input() chartOptions;
  @Input() chartLegend;
  @Input() chartType;


  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  //   // We use these empty structures as placeholders for dynamic theming.
  //   scales: { xAxes: [{}], yAxes: [{}] }
  // };

  // public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  // public barChartType: ChartType = 'bar';
  // public barChartLegend = true;
  // public barChartData: ChartDataSets[] = [
  //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  //   { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  // ];

  constructor() {
  }

  ngOnInit() {
    // console.log(this.chartLabels);
    // console.log(this.chartData);
    // console.log(this.chartOptions);
    // console.log(this.chartLegend);
    // console.log(this.chartType);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }
}
