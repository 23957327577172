import { Component } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LanguageService } from '../../services/language.service'
import { MenuService } from '../../services/menu.service';
import { Router } from '@angular/router';
import { TranslateAgencyOperatorService } from 'src/app/services/translate-agency-operator.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {

  userEmail: string;
  userRol: string;
  nameUser: string;
  LOCKER_USER_CODE: String = 'DCV';
  roles: any;
  rolTrad: any;

  constructor(private languageService: LanguageService,
    private menuService: MenuService,
    private router: Router,
    private translateAgencyOpertator: TranslateAgencyOperatorService) {
      // Al cargar el navbar automáticamente se inicializa el lenguaje de la aplicación
    this.languageService.initLanguageConfig();
    this.rolTrad = {
      DCV: 'Propietario del casillero',
      OPB:  'Operario Bodega',
      ADM: 'Admin',
      OPD: 'Operario de bodega pais destino',
      OPC: 'Operario Contabilidad',
      OPAG:  'Operario de Agencia',
      SADM: 'SUPER ADMIN',
      NOROL: 'Sin Rol',
      RPU: 'Recolector PickUp',
      CALO: 'Carrier Local',
      OBCV: 'Operario Bodega Casillero Virtual',
      ACV: 'Admin casillero virtual',
      OD: 'Operario de despacho',
      OR: 'Operario de reajuste',
      OCOD: 'Operario COD',
    };
    this.getUserInformation();
  }

  // Método para cambiar el lenguaje de la aplicación
  changeLanguage(language: string) {
    this.translateAgencyOpertator.toggle(language);
    this.languageService.changeLanguage(language);
    this.menuService.loadMenus();
  }

  getFlagURL(): string {
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

  getUserInformation() {
    const token = localStorage.getItem('currentUser');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    //this.roles = decodedToken.roles;
    //this.roles = decodedToken.roles[0];
   const tokenInfo = localStorage.getItem('tokenInfo');
    const helperInfo = new JwtHelperService();
    const decodedTokenInfo = helperInfo.decodeToken(tokenInfo);
    this.roles = decodedTokenInfo.roles;
    
    this.nameUser = decodedToken.firstname;
    this.userRol = this.rolTrad[this.roles];
    this.userEmail = decodedToken.firstname + '-' + this.rolTrad[this.roles];
  }
}
