import {Component, OnInit} from '@angular/core';
import {User} from '../../models/user';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {sha256} from 'node_modules/js-sha256';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from '../../services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../shared/auth/auth.service';
import {AlertMessageService} from '../../services/alert-message.service';
import {LanguageService} from '../../services/language.service';
import {RegisterService} from "../../services/register.service";
import { LoginService } from '../../services/login.service';
import { MultilingualHeaderImageService } from 'src/app/services/multilingual-header-image.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',
    '../../../assets/css/form-elements.css',
    '../../../assets/css/style.css'
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  user = null;
  returnUrl: string;
  language:string;
  logoURL:string;
  urlBackground:any;
  ruta:string;
  viewPass: string;
  password: string;
  view: boolean;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private loginService: LoginService,
              private authenticationService: AuthenticationService,
              public translate: TranslateService,
              private authService: AuthService,
              private alertMessageService : AlertMessageService,
              private languageService: LanguageService,
              private multilingualHeadersService: MultilingualHeaderImageService) {

    let browserLang: string = translate.getBrowserLang();
    browserLang = browserLang.match(/en|es/) ? browserLang : 'en';
    translate.use(browserLang);
    this.language = browserLang;
    localStorage.setItem('language', browserLang);
    this.urlBackground = {'background-image':this.getBackgroundURL()};
    ////console.log(this.urlBackground);
    this.user = new User();
    this.getLogin();
    this.password = "password";
    this.viewPass = "textShow"; 
    this.view= true;
  }

  getLogin() {
    this.loginService.getLogin().subscribe(result => {
        this.logoURL = result['logo'];
      }, error => {
        const messageCode = 'CE';
        this.alertMessageService.showErrorMessage(this.language, messageCode);
      }
    );
  }

  // Método para cambiar el lenguaje de la aplicación
  ChangeLanguage(language: string) {
    this.translate.use(language);
    this.language = language;
    localStorage.setItem('language', language);
    this.urlBackground = {'background-image':this.getBackgroundURL()};
  }

  ngOnInit() {
    this.validateForm();
    this.authenticationService.logout();
  }

  validateForm() {
    this.loginForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'password': ['', Validators.required]
    });
    console.log();
  }

  login(post) {
    let data = {
      'email': post.email.trim().toLowerCase(),
      'password': sha256(post.password)
    };
    this.authenticationService.login(data).subscribe(result => {
      if (result['status'] === true) {
        localStorage.setItem('currentUser', result['token']);
        localStorage.setItem('tokenInfo', result['tokenInfo']);
        localStorage.setItem('avatarURL', result['avatar']);
        localStorage.setItem('logoURL', this.logoURL);

        this.router.navigate(['/']);
        this.authService.setUrlPermissions();
      } else {
        const messageCode = result['code'];
        this.alertMessageService.showErrorMessage(this.language, messageCode);
        this.loginForm.reset();
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    });
  }

  getFlagURL():string{
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

  getBackgroundURL() {
    const language = this.languageService.getCurrentLanguage();
    let hola= `url(${this.multilingualHeadersService.getHeaderURL('login', language)})`;
    let hola2= 'url("../../../assets/img/Logo v1-5.png")'
    this.ruta = "../../../assets/img/Logo v1-5.png";
    return hola2
  }

  changePrivateKeyInputPass(){
    if(this.password == "text"){
   this.password = "password";
   this.viewPass = "textShow";
   this.view= true;
 }else{
   this.password = "text";
   this.viewPass = "textHide";
   this.view = false;
 }
}
}
