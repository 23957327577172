import { Injectable } from '@angular/core';
import {ConfigService} from './config.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ListDutyTaxesService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getDutyTaxes(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/listDutyTax'), {headers});
  }

  getActiveDutyTaxes(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    if(token){
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.get(this.configService.serverIp.concat('/listActiveDutyTax'), {headers});
    }else{
      return this.http.get(this.configService.serverIp.concat('/listActiveDutyTax'));
    }
    
  }


  getSimpleDutyTax(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getSimpleDutyTax'), {headers});

  }



  getDutyTax(dutyTaxId: string): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('dutyTax_id', dutyTaxId);
    return this.http.get(this.configService.serverIp.concat('/getDutyTax'), {headers, params});
  }

  editDutyTax(data): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });

    return this.http.post(this.configService.serverIp.concat('/editDutyTax'), data, {headers});
  }

  activateDeactivateService(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/deactivateDutyTax'), data, {headers});
  }
  
}

