import { Injectable, EventEmitter, Output } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class IconSideBarService {
  imagen;
  @Output() change: EventEmitter<string> = new EventEmitter();
  updateIcon() {
    this.imagen = localStorage.getItem('logoURL');
    this.change.emit(this.imagen);
  }
  constructor() { 
    
  }
  
}
