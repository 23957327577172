import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LanguageService } from '../../services/language.service';
import { AlertMessageService } from '../../services/alert-message.service';
import { CompanyInfoService } from '../../services/company-info.service';
import { RequestShippingPaymentService } from 'src/app/services/request-shipping-payment.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss']
})
export class RegisterCompanyComponent implements OnInit {

  
  editCompanyInfoForm: FormGroup;
  newLogo: any;
  companyLogo: String;
  countries: Array<any>;
  cities: Array<any>;
  states: Array<any>;
  themes: Array<any>;
  stateSelected = false;
  countrySelected = false;
  language:string;
  options:Array<number>;

  constructor(private router: Router,
    private languageService: LanguageService,
    private alertMessageService: AlertMessageService,
    private companyInfoService: CompanyInfoService,
    private requestShippingPaymentService: RequestShippingPaymentService,
    private formBuilder: FormBuilder,
    private translate:TranslateService) {
    translate.setDefaultLang('es')
    this.options = [];
    this.getCountries();
    this.editCompanyInfoForm = new FormGroup({
      companyId: new FormControl(),
      companyName: new FormControl(),
      companyDomain: new FormControl(),
      companyAddress: new FormControl(),
      companyPhone: new FormControl(),
      companyEmail: new FormControl(),
      payPalPercentage: new FormControl(),
      creditCardPercentage: new FormControl(),
      userAgreement: new FormControl(),
      companyLogo: new FormControl(),
      country_id: new FormControl(),
      state_id: new FormControl(),
      city_id: new FormControl(),
      zipCode: new FormControl(),
      paymentNotes: new FormControl(),
      theme_id: new FormControl(),
      pdfLabelText: new FormControl(),
      valuesDeclaration: new FormControl()
    })
  }

  ngOnInit() {
    ////console.log('hola')
    ////console.log(this.editCompanyInfoForm);
    this.llenarOptions();
    this.getCompanyInfo();
  }

  llenarOptions(){
    for (let i = 0; i<101; i++){
      this.options[i] = i;
    }
  }


  initEditInfoForm(info) {
    this.editCompanyInfoForm = this.formBuilder.group({
      'companyId': ['' + info.id],
      'companyName': ['' + info.name],
      'companyDomain': ['' + info.domain],
      'companyAddress': ['' + info.address],
      'companyPhone': ['' + info.phone],
      'companyEmail': ['' + info.email],
      // 'payPalPercentage': ['' + info.percentagepaypal],
      // 'creditCardPercentage': ['' + info.percentagecreditcard],
      'userAgreement': ['' + info.useragreement],
      'companyLogo': [info.logo],
      'country_id': [info.country],
      'state_id': [info.state],
      'city_id': [info.city],
      'zipCode': [info.zipcode],
      'paymentNotes': [info.paymentNotes],
      'theme_id': [info.theme_id],
      'pdfLabelText': [info.pdfLabelText],
      'valuesDeclaration': [info.valuesDeclaration]
    })
  }

  getCompanyInfo() {
    const language = this.languageService.getCurrentLanguage();
    this.companyInfoService.getCompanyInfo().subscribe(result => {
      ////console.log(result);
      if (result['status']) {
        const company = result['company'];
        this.initEditInfoForm(result['company']);
        this.getStates(company.country);
        this.getCities(company.state);
        this.companyLogo = company['log'];
      } else {
        this.alertMessageService.showErrorMessage(language, result['code']);
      }
    }, error => {
      this.alertMessageService.showErrorMessage(language, 'CE');
    });
  }

  loadLogo($event) {
    this.newLogo = null;
    let maxSizeFile = 5000000;
    let language = this.languageService.getCurrentLanguage();

    if ($event.target.files.length === 1) {
      let file = $event.target.files[0];

      if (file.size > maxSizeFile) {
        this.alertMessageService.showErrorMessage(language, "EFT");
      }
      else if (!['image/jpeg', 'image/png'].includes(file.type)) {
        this.alertMessageService.showErrorMessage(language, "TPF");
      }
      else {
        this.newLogo = file;
      }

    }
  }

  saveInfo() {
    let language = this.languageService.getCurrentLanguage();
    const values = this.editCompanyInfoForm.value;
    let formData = new FormData();
    formData.append('name', values.companyName);
    formData.append('domain', values.companyDomain);
    formData.append('address', values.companyAddress);
    formData.append('phone', values.companyPhone);
    formData.append('email', values.companyEmail);
    // formData.append('percentagepaypal', values.payPalPercentage);
    // formData.append('percentagecreditcard', values.creditCardPercentage);
    formData.append('useragreement', values.userAgreement);
    formData.append('country_id', values.country_id);
    formData.append('theme_id', values.theme_id);
    formData.append('state_id', values.state_id);
    formData.append('city_id', values.city_id);
    formData.append('zipCode', values.zipCode);
    formData.append('paymentNotes', values.paymentNotes);
    formData.append('logo', this.newLogo);//--->File
    formData.append('pdfLabelText', values.pdfLabelText);
    formData.append('valuesDeclaration', values.valuesDeclaration);
    localStorage.setItem('theme', values.theme_id)
    this.companyInfoService.updateCompanyInfo(formData).subscribe(result => {
      const messageCode = result['code'];
      ////console.log(result);
      if (result['status'] === true) {
        this.alertMessageService.showSuccessMessage(language, messageCode);
        let url = result['url'];
        this.getCompanyInfo();
      }
      else {
        this.alertMessageService.showErrorMessage(language, messageCode);
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    });
  }

  getCountries() {
    let language = this.languageService.getCurrentLanguage();
    this.requestShippingPaymentService.getCountries().subscribe(result => {
      if (result['status'] === true) {
        this.countries = result['countries'];
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    }
    );
  }

  onChangeCountry($event) {
    const country = this.editCompanyInfoForm.controls['country_id'].value;
    if (country) {
      this.countrySelected = true;
      this.getStates(country);
    }
  }



  onChangeState($event) {
    const state = this.editCompanyInfoForm.controls['state_id'].value;
    if (state) {
      this.stateSelected = true;
      this.getCities(state);
    }
  }

  getCities(stateId) {
    let language = this.languageService.getCurrentLanguage();
    this.requestShippingPaymentService.getCitiesByState(stateId).subscribe(result => {
      if (result['status'] === true) {
        this.cities = result['cities'];
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    });
  }

  getStates(countryId) {
    let language = this.languageService.getCurrentLanguage();
    this.requestShippingPaymentService.getStatesByCountry(countryId).subscribe(result => {
      if (result['status'] === true) {
        this.states = result['states'];
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    }
    );
  }

}
