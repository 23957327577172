import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    currentDate: Date = new Date();
    socialMediaInfo = [];
    constructor(private socialMedia: LoginService,
        private router: Router) {
    }

    ngOnInit() {
        this.getSocialMedia();
    }

    getSocialMedia() {
        const url = this.router.url;
        if (!['/shared-calculator', '/search-guide-status'].includes(url)) {
            this.socialMedia.getSocialMedia().subscribe(res => {
                this.socialMediaInfo = res['socialMedia'];
            });
        }
    }
}
