import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themes: Array<any>;
  configService: ConfigService;
  constructor(private http: HttpClient,
   ) {
     this.configService = new ConfigService;
    this.themes = [
      {
        id: 0,
        name: 'Default Theme',
        // themeprincipal: '#d9524f',
        // themesecondary: '#009DA0',
        themeprincipal: '#423f3e',
        themesecondary: '#423f3e',
        // themefooter:  '#b15254',
        themefooter:  '#423f3e',
        // themebtnprincipal: '#d9524f',
        themebtnprincipal: '#6EBA76',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#6EBA76',
        // themebtnsecondary: '#009DA0',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#FF7A4E',
        dashboard2: '#FF8D60',
        dashboard3: '#FFAF90',
        dashboard4: '#FFC6B0',
        dashboard5: '#FF7336',
        themefont: '#FFFFFF',
        themeSocialMedia: '#009DA0',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 1,
        name: 'Blue Theme',
        themeprincipal: '#67B7E0',
        themesecondary: '#67B7E0',
        themefooter: ' #67B7E0',
        themebtnprincipal: '#d9524f',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: 'rgb(3, 121, 180)',
        themebtnsecondaryactive: 'rgb(1, 76, 114)',
        dashboard1: '#0277BD',
        dashboard2: '#039BE5',
        dashboard3: '#29B6F6',
        dashboard4: '#81D4FA',
        dashboard5: '#039BE5',
        themefont: '#FFFFFF',
        themeSocialMedia: 'rgb(0, 33, 34)',
        domain:['#e67e22','#cb4335','#f0b27a',' #880e4f','#45b39d','#2471a3 ']
      },
      {
        id: 2,
        name: 'Green Theme',
        themeprincipal: '#009238',
        themesecondary: '#00973B',
        themefooter:  '#00973B',
        themebtnprincipal: '#d9524f',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#2E7D32',
        themebtnsecondaryactive: 'rgb(27, 73, 29)',
        dashboard1: '#2E7D32',
        dashboard2: '#43A047',
        dashboard3: '#66BB6A',
        dashboard4: '#A5D6A7',
        dashboard5: '#43A047',
        themefont: '#FFFFFF',
        themeSocialMedia: 'rgb(0, 33, 34)',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 3,
        name: 'Orange Theme',
        themeprincipal: '#FF7336',
        themesecondary: '#FF7336',
        themefooter:  '#FF7336',
        themebtnprincipal: '#d9524f',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: 'rgb(112, 50, 23)',
        themebtnsecondaryactive: 'rgb(78, 35, 16)',
        dashboard1: '#FF7A4E',
        dashboard2: '#FF8D60',
        dashboard3: '#FFAF90',
        dashboard4: '#FFC6B0',
        dashboard5: '#FF7336',
        themefont: '#FFFFFF',
        themeSocialMedia: 'rgb(112, 50, 23)',
        themeDashboard: '#43A047',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 4,
        name: 'Purple Theme',
        themeprincipal: '#1a2a6c',
        themesecondary: '#b21f1f',
        themefooter:  '#480058',
        themebtnprincipal: '#b21f1f',
        themebtnprincipalactive: '#791515',
        themebtnsecondary: '#5E35B1',
        themebtnsecondaryactive: 'rgb(50, 28, 94)',
        dashboard1: '#673AB7',
        dashboard2: '#7E57C2',
        dashboard3: '#9575CD',
        dashboard4: '#B39DDB',
        dashboard5: '#673AB7',
        themefont: '#FFFFFF',
        themeSocialMedia: '#b21f1f',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 5,
        name: 'Christmas Theme',
        themeprincipal: '#009238',
        themesecondary: '#F91900',
        themefooter:  '#00973B',
        themebtnprincipal: '#db3f3d',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#2E7D32',
        themebtnsecondaryactive: 'rgb(27, 73, 29)',
        dashboard1: '#2E7D32',
        dashboard2: '#43A047',
        dashboard3: '#66BB6A',
        dashboard4: '#A5D6A7',
        dashboard5: '#43A047',
        themefont: '#FFFFFF',
        themeSocialMedia: '#F91900',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']

      },
      {
        id: 6,
        name: 'Minimalist Theme',
        themeprincipal: '#757575',
        themesecondary: '#757575',
        themefooter:  '#757575',
        themebtnprincipal: '#91603F',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#308C91',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#FF7A4E',
        dashboard2: '#FF8D60',
        dashboard3: '#FFAF90',
        dashboard4: '#FFC6B0',
        dashboard5: '#FF7336',
        themefont: '#FFFFFF',
        themeSocialMedia: '#009DA0',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 7,
        name: 'Simple Theme',
        themeprincipal: '#23C7EA',
        themesecondary: '#23C7EA',
        themefooter:  '#23C7EA',
        themebtnprincipal: '#69727B',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#009DA0',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#FF7A4E',
        dashboard2: '#FF8D60',
        dashboard3: '#FFAF90',
        dashboard4: '#FFC6B0',
        dashboard5: '#FF7336',
        themefont: '#FFFFFF',
        themeSocialMedia: '#009DA0',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 8,
        name: 'Yellow Theme',
        themeprincipal: '#ffDE16',
        themesecondary: '#ffDE16',
        themefooter:  '#ffDE16',
        themebtnprincipal: '#343a3f',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#343a3f',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#FF7A4E',
        dashboard2: '#FF8D60',
        dashboard3: '#FFAF90',
        dashboard4: '#FFC6B0',
        dashboard5: '#FF7336',
        themefont: '#343a3f',
        themefontfooter: '#343a3f',
        themeSocialMedia: '#343a3f',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 9,
        name: 'Blue-Orange theme',
        themeprincipal: '#67B7E0',
        themesecondary: '#67B7E0',
        themefooter:  '#67B7E0',
        themebtnprincipal: '#f1812b',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#343a3f',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#FF7A4E',
        dashboard2: '#FF8D60',
        dashboard3: '#FFAF90',
        dashboard4: '#FFC6B0',
        dashboard5: '#FF7336',
        themefont: '#ffffff',
        themefontfooter: '#343a3f',
        themeSocialMedia: '#343a3f',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 10,
        name: 'Blue-Dark',
        themeprincipal: '#07487d',
        themesecondary: '#07487d',
        themefooter:  '#07487d',
        themebtnprincipal: '#f1812b',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#343a3f',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#FF7A4E',
        dashboard2: '#FF8D60',
        dashboard3: '#FFAF90',
        dashboard4: '#FFC6B0',
        dashboard5: '#FF7336',
        themefont: '#ffffff',
        themefontfooter: '#343a3f',
        themeSocialMedia: '#343a3f',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },
      {
        id: 11,
        name: 'Green-Cold',
        themeprincipal: '#478476',
        themesecondary: '#478476',
        themefooter:  '#478476',
        themebtnprincipal: '#ffd740',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#343a3f',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#ffd740',
        dashboard2: '#ffd740',
        dashboard3: '#ffd740',
        dashboard4: '#ffd740',
        dashboard5: '#ffd740',
        themefont: '#ffffff',
        themefontfooter: '#343a3f',
        themeSocialMedia: '#343a3f',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      },

      {
        id: 12,
        name: 'Black',
        themeprincipal: '#000000',
        themesecondary: '#000000',
        themefooter:  '#000000',
        themebtnprincipal: '#ffd740',
        themebtnprincipalactive: 'rgba(243, 91, 63, 0.5)',
        themebtnsecondary: '#343a3f',
        themebtnsecondaryactive: 'rgba(0, 212, 229, 0.5)',
        dashboard1: '#ffd740',
        dashboard2: '#ffd740',
        dashboard3: '#ffd740',
        dashboard4: '#ffd740',
        dashboard5: '#ffd740',
        themefont: '#ffffff',
        themefontfooter: '#343a3f',
        themeSocialMedia: '#343a3f',
        domain:['#e67e22','#cb4335','#f0b27a','#9e1a60','#45b39d','#2471a3 ']
      }
    ];
   }


   getThemes() {
     return this.themes;
   }

   applyTheme(id) {

    // Las variables globales que aquí se modifican para el scss se encuentran en src/assets/sass/scss/_global-variables.scss
    // Los botones se modifican de acuerdo al tema en el archivo src/assets/sass/systemStyles.scss
    document.documentElement.style.setProperty('--theme-principal', this.themes[id].themeprincipal);
    document.documentElement.style.setProperty('--theme-secondary', this.themes[id].themesecondary);
    document.documentElement.style.setProperty('--theme-footer', this.themes[id].themefooter);
    document.documentElement.style.setProperty('--theme-btn-principal', this.themes[id].themebtnprincipal);
    document.documentElement.style.setProperty('--theme-btn-principal-active', this.themes[id].themebtnprincipalactive);
    document.documentElement.style.setProperty('--theme-btn-secondary', this.themes[id].themebtnsecondary);
    document.documentElement.style.setProperty('--theme-btn-secondary-active', this.themes[id].themebtnsecondaryactive);
    document.documentElement.style.setProperty('--dashboard1', this.themes[id].dashboard1);
    document.documentElement.style.setProperty('--dashboard2', this.themes[id].dashboard2);
    document.documentElement.style.setProperty('--dashboard3', this.themes[id].dashboard3);
    document.documentElement.style.setProperty('--dashboard4', this.themes[id].dashboard4);
    document.documentElement.style.setProperty('--dashboard5', this.themes[id].dashboard5);
    document.documentElement.style.setProperty('--theme-font', this.themes[id].themefont);
    document.documentElement.style.setProperty('--social-media', this.themes[id].themeSocialMedia);
    document.documentElement.style.setProperty('--theme-login', this.themes[id].themefooter);
    document.documentElement.style.setProperty('--theme-font-footer', this.themes[id].themefontfooter);
    document.documentElement.style.setProperty('--theme-themeDashboard', this.themes[id].themeDashboard);
    document.documentElement.style.setProperty('--theme-domain', this.themes[id].domain);

   }

   getCompanyTheme() {
    this.http.get(this.configService.serverIp.concat('/getCompanyTheme')).subscribe((res: Response) => {
      localStorage.setItem('theme', res['theme_id']);
      this.applyTheme(res['theme_id']);
    });
   }

}
