import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertMessageService } from '../../services/alert-message.service';
import { Router} from "@angular/router";
import {AuthService} from '../../shared/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  emailForm: FormGroup;
  emailSent:boolean = false;
  logoURL:string;
  
  constructor(private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private alertMessageService: AlertMessageService) {
    this.translate.use(this.languageService.getCurrentLanguage());
  }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.emailForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  getFlagURL():string{
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

  // Método para cambiar el lenguaje de la aplicación
  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  forgotPassword() {
    const data = this.emailForm.value;
    const language = this.languageService.getCurrentLanguage();
    this.authenticationService.forgotPassword(data).subscribe(result => {
      ////console.log(result);
      if (result['status']) {
        this.alertMessageService.showSuccessMessage(language, result['code']);
        this.emailSent = true;
        this.router.navigate(['/']);
        this.authService.setUrlPermissions();
      } else {
        this.alertMessageService.showErrorMessage(language, result['code']);
      }
    }, error => {
      this.alertMessageService.showErrorMessage(language, 'CE');
    });
  }

}
