import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { CompanyInfoService } from 'src/app/services/company-info.service';


@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent {
    constructor(private companyService: CompanyInfoService,
        private themeService:ThemeService){
          this.getCompanyInfo();
      }
    
      getCompanyInfo(){
        if(localStorage.getItem('theme'))
          this.themeService.applyTheme(localStorage.getItem('theme'));
        else
          this.themeService.getCompanyTheme();
      }
}