import { Component, OnInit } from '@angular/core';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss']
})

export class ContentLayoutComponent  {
    constructor(private companyService: CompanyInfoService,
        private themeService:ThemeService){
          this.themeService.getCompanyTheme();
      }
    
      
}